<template>
    <div class="alerta" @click="close()" v-if="status == true">
      <div class="alerta__card" :class="{ 'alerta__card--info': type == 'info', 'alerta__card--error': type == 'error', }">
        <p>{{message}}</p>
        <v-icon class="alerta__card__close_icon">mdi-close</v-icon>
      </div>
  </div>
</template>

<style>
.alerta {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: end;
  padding-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.alerta__card {
  min-width: 300px;
  max-width: 80%;
  border-radius: 20px;
  padding: 15px;
  padding-left: 30px;
  padding-top: 30px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 10px;
}

.alerta__card--info {
  color: #0c5460;
  background-color: #d1ecf1;
  border: 2px solid #bee5eb;
}

.alerta__card--error {
  color: #721c24;
  background-color: #f8d7da;
  border: 2px solid #f5c6cb;
}

.alerta__card__close_icon {
    padding-top: 10px;
}
</style>

<script>
  export default {
    methods: {
      close() {
        this.$emit("close");
      },
    },
    props: [
      "status",
      "message",
      "type",
    ],
  };
</script>
