import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    usuarioDB: ''
  },
  mutations: {
    obtenerUsuario(state, payload){
      state.token = payload;
      if(payload === ''){
        state.usuarioDB = ''
      }else{
        state.usuarioDB = decode(payload);
        console.log(state.usuarioDB);
        // router.push({name: 'notas'})
      }
    }
  },
  actions: {
    guardarUsuario({commit}, data){
      const { token, refreshToken} = data;
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      commit('obtenerUsuario',token);
    },
    cerrarSesion({commit}){
      commit('obtenerUsuario','');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.href = `${process.env.VUE_APP_APPS_URL}`;
    },
    leerToken({commit}){
      const token = localStorage.getItem('token');
      if(token){
        commit('obtenerUsuario',token);
      }else{
        commit('obtenerUsuario','');
      }
    }
  },
  modules: {
  },
  getters: {
    estaActivo: state => !!state.token
  }
})
