import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VuetifyConfirm from 'vuetify-confirm';

import App from './App.vue';
import { clearLocalStorageAndRedirectToLogin } from './helpers/auth';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

axios.interceptors.request.use(function (config) {
    config.headers.token = localStorage.getItem('token');
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

const refreshAuthLogic = failedRequest =>
  axios.post('/auth/refreshToken', { refreshToken: localStorage.getItem('refreshToken') })
    .then(tokenRefreshResponse => {
      const { data } = tokenRefreshResponse;
      localStorage.setItem('token', data.token);
      failedRequest.response.config.headers['token'] = data.token;
      return Promise.resolve();
    })
    .catch((error) => {
      clearLocalStorageAndRedirectToLogin();
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic);

let refreshToken = localStorage.getItem('refreshToken');
if(refreshToken != null) {
  axios.get(`/auth/refreshToken/${localStorage.getItem('refreshToken')}`)
    .then((r) => {
      if (r.data.data.refreshToken.isExpired) clearLocalStorageAndRedirectToLogin();
    })
    .catch((error) => clearLocalStorageAndRedirectToLogin());
}

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Aceptar',
  buttonFalseText: 'Cancelar',
  color: 'warning',
  icon: 'warning',
  title: 'Warning',
  width: 350,
  property: '$confirm'
})

Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
