<template>
    <nav>
        <v-app-bar app dark="" color="#298A45">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <v-card-actions>
                <v-list-item>
                    <v-list-item-avatar class="justify-center">
                        <v-avatar
                        color="#FFFFFF"
                        size="56"
                        class="green--text"
                        >
                        {{initialUser}}
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{nameUser}} {{apellUser}}</v-list-item-title>
                        <v-list-item-subtitle>{{emailUser}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                fab
                                dark
                                small
                                color="error"
                                @click="cerrarSesion()"
                                v-bind="attrs"
                                v-on="on"
                                >
                                    <v-icon
                                    >fas fa-sign-out-alt</v-icon>
                                </v-btn>
                            </template>
                            <span>Cerrar sesión</span>
                        </v-tooltip>
                    </v-list-item-action>
                </v-list-item>
            </v-card-actions>
      
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" dark="" app color="#FFFFFF" >
            <v-row class="justify-center">
                <v-col cols="12" md="11">
                    <v-img width="200px" class="ml-2" src="survey.png"></v-img>
                </v-col>
            </v-row>
            <v-list>
                <v-list-item router to="/home" v-if="estaActivo" active-class="border">
                    <v-list-item-action>
                        <v-icon color="#298A45">fas fa-home</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="grey--text">Inicio</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item router to="/demografia" v-if="estaActivo" active-class="border">
                    <v-list-item-action>
                        <v-icon color="#298A45">fas fa-chart-bar</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="grey--text">Demografía</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item router to="/dimension" v-if="estaActivo" active-class="border">
                    <v-list-item-action>
                        <v-icon color="#298A45">fas fa-chart-line</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="grey--text">Dimensión</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item router to="/encuesta" v-if="estaActivo" active-class="border">
                    <v-list-item-action>
                        <v-icon color="#298A45">fas fa-chart-pie</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="grey--text">Encuesta</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item router to="/proceso" v-if="estaActivo" active-class="border">
                    <v-list-item-action>
                        <v-icon color="#298A45">fas fa-briefcase</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="grey--text">Proceso</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item router to="/ayuda" v-if="estaActivo" active-class="border">
                    <v-list-item-action>
                        <v-icon color="#298A45">fas fa-question-circle</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="grey--text">Ayuda</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-col>
                <template>
                    <v-carousel
                    cycle
                    dark
                    height="230"
                    hide-delimiter-background
                    show-arrows-on-hover
                    >
                        <v-carousel-item v-for="(slide, i) in slides" :key="i">
                            <v-sheet
                            color="white"
                            height="80%"
                            align="center"
                            tile
                            class="rounded"
                            >
                                <v-col class="fill-height" align="center" justify="center">
                                    <div class="grey--text">
                                        <h4>{{ slide.comunicado }}</h4>
                                        <v-img width="50px" :src="slide.img"></v-img>
                                        <p class="ma-2">{{ slide.text }}</p>
                                    </div>
                                </v-col>
                            </v-sheet>                
                        </v-carousel-item>
                    </v-carousel>
                </template>
            </v-col>
        </v-navigation-drawer>
    </nav>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
    data: ()=> ({
        drawer:true,
        slides: [
            {comunicado: 'Tip 1', text: 'Generar confianza para mejorar el clima laboral', img: 'man1.png'},
            {comunicado: 'Tip 2', text: 'Establecer sistemas de recompensas para mejorar el clima laboral', img: 'man2.png'},
            {comunicado: 'Tip 3', text: 'La transparencia, clave del clima laboral', img: 'man3.png'},
            {comunicado: 'Tip 4', text: 'Apostar la formación para tener un buen clima laboral', img: 'man4.png'},
        ],
        nameUser:'',
        apellUser: '',
        initialUser: '',
        emailUser: ''
    }),
    methods: {
        ...mapActions(['cerrarSesion','leerToken']),
        getInformation(){
            this.axios.get('/user')
                .then(res=> {
                    this.nameUser = res.data.nombre
                    this.apellUser = res.data.apellido
                    this.emailUser = res.data.email
                    const firstInitial = res.data.nombre.charAt(0)
                    const secondInitial = res.data.apellido.charAt(0)
                    this.initialUser = firstInitial.toUpperCase() + ' ' + secondInitial.toUpperCase()
                })
                .catch(e=>{
                console.log(e.response);
                })
        }
    },
    computed: {
        ...mapGetters(['estaActivo']),
        ...mapState(['token']),
    },
    created() {
        this.getInformation();
    },
}
</script>

<style scoped>
    .border{
        border-left: 4px solid #2c3e50;
    }
    .rounded{
        border-radius: 30px;
    }
    .ligne{
        border-right: solid 1px #95a5a6;
    }
</style>