import Vue from 'vue';
import VueRouter from 'vue-router';

import { clearLocalStorageAndRedirectToLogin } from '../helpers/auth';

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    alias: ['/'],
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/demografia',
    name: 'Demografia',
    component: () => import(/* webpackChunkName: "about" */ '../views/Demografia.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/dimension',
    name: 'Dimension',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dimension.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/encuesta',
    name: 'Encuesta',
    component: () => import(/* webpackChunkName: "about" */ '../views/Encuesta.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/proceso',
    name: 'Proceso',
    component: () => import(/* webpackChunkName: "about" */ '../views/Proceso.vue'),
    meta: {requireAuth: true}
  }
  ,
  {
    path: '/ayuda',
    name: 'Ayuda',
    component: () => import(/* webpackChunkName: "about" */ '../views/Ayuda.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/sso',
    name: 'SSO',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sso.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const rutaProtegida = to.matched.some(record => record.meta.requireAuth);
  const refreshToken = localStorage.getItem('refreshToken');
  if(rutaProtegida && refreshToken == null){
    clearLocalStorageAndRedirectToLogin();
    return;
  }
  next();
})

export default router
