<template>
  <v-app>
    <Navbar v-if="estaActivo"></Navbar>
    <v-main>
      <router-view :popup="popupAlert"></router-view>
    </v-main>
   <alertas :status="alertas.status" :message="alertas.message" :type="alertas.type" @close="closePopupAlert()"></alertas>
 </v-app>
</template>

<script>

import Navbar from '@/components/Navbar'
import Alertas from '@/components/features/alertas/Alertas.vue';
import {mapActions, mapGetters} from 'vuex'

export default {
  methods: {
    ...mapActions(['cerrarSesion','leerToken']),
    popupAlert(message, type) {
      this.alertas = {
        status: true,
        message: message,
        type: type,
      };
    },
    closePopupAlert() {
      this.alertas = {
        status: false,
        message: "",
        type: "",
      };
    }
  },
  components: {
    Navbar,
    Alertas
  },
  computed: {
    ...mapGetters(['estaActivo'])
  },
  data() {
    return {
      alertas: {
        status: false,
        message: "",
        type: "info",
      },
    }
  },
};
</script>

<style>
    html{
      overflow-y: auto!important; /* All browsers without overlaying scrollbars */
    }
</style>
